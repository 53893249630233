const special_characters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const special_characters_for_email = /[ `!#$%^&*()+=\[\]{};':"\\|,<>\/?~]/;

export function inputsAreValid(
  { email = "", phone = "", phone_hints = { start: "", end: "" } },
  {
    toast,
    toast_config,
    showError,
    validate_phone = true,
    validate_phone_partially = false,
  }
) {
  if (!showError) showError = (message) => toast.error(message, toast_config);

  let valid = true;
  if (!email || email === "" || !email.includes("@") || !email.includes(".")) {
    showError("Invalid email address");
    valid = false;
  }
  if (special_characters_for_email.test(email)) {
    showError("Invalid email address. Special characters are not allowed");
    valid = false;
  }

  if (validate_phone) {
    if (!phone || phone === "" || special_characters.test(phone)) {
      showError("Invalid phone number. Only whole numbers are allowed");
      valid = false;
    }

    if (!validate_phone_partially) {
      if (phone.length < 10) {
        showError("Invalid phone number. Less than 11 characters found");
        valid = false;
      }
      if (
        (phone.indexOf(phone_hints.start) !== 0 &&
          `0${phone.slice(3, 3 + phone_hints.start.length - 1)}`.indexOf(
            phone_hints.start
          ) !== 0) ||
        phone.lastIndexOf(phone_hints.end) !==
          phone.length - phone_hints.end.length
      ) {
        showError(
          `Wrong phone number. The correct phone number must start with '${phone_hints.start}' and end with '${phone_hints.end}'`
        );
        valid = false;
      }
    }
  }

  return valid;
}

export function prepare_inputs({ phone = "" }) {
  const return_object = { phone };
  const DEFAULTS = { PHONE_PREFIX: "234" };
  phone = `${phone}`;

  if (phone.length < 13 && phone.indexOf(DEFAULTS.PHONE_PREFIX) !== 0)
    return_object.phone = `${DEFAULTS.PHONE_PREFIX}${
      phone.charAt(0) === "0" ? phone.slice(1) : phone
    }`;

  return return_object;
}

export function canSubmit(is_submitting_atm) {
  if (is_submitting_atm)
    console.log("Is currently submitting. Kindly try again in a moment");
  return !is_submitting_atm;
}
