import React from "react";

import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./phone-brk-edit.css";

import { initializeFromQuery } from "../utils/url";
import { capitalize } from "../utils/string";
import { inputsAreValid, prepare_inputs, canSubmit } from "../utils/form";
import { verifyPhone } from "../apis/server";

const PhoneBrkEdit = (props) => {
  const history = useHistory();

  const { toast, toast_config, CHILD_URLS } = props;

  const [link_code, setLinkCode] = React.useState("");
  const [first_name, setFirstName] = React.useState("Name"); // the only guy allowed to have a default
  const [phone_hints, setPhoneHints] = React.useState({});
  const [phone, setCompletePhone] = React.useState("");
  const [email, setEmailAddress] = React.useState("");
  const [is_submitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    const back_degree = -3;

    let phone_hints = {};
    initializeFromQuery({
      url: window.location.search,
      callback_per_query: [
        {
          query_tag: "slug",
          callback: setLinkCode,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid link-code detected. Kindly retry later", {
                  ...toast_config,
                  onClose: () => history.go(back_degree),
                })
              : true,
        },
        {
          query_tag: "first_name",
          callback: (f_n) => setFirstName(capitalize(f_n)),
          validate: (v) => ![null, undefined, ""].includes(v),
        },
        {
          query_tag: "hint_start",
          callback: (start) => {
            phone_hints = { ...phone_hints, start };
            setPhoneHints(phone_hints);
          },
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid hint-start detected. Kindly retry later", {
                  ...toast_config,
                  onClose: () => history.go(back_degree),
                })
              : true,
        },
        {
          query_tag: "hint_end",
          callback: (end) => {
            phone_hints = { ...phone_hints, end };
            setPhoneHints(phone_hints);
          },
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid hint-end detected. Kindly retry later", {
                  ...toast_config,
                  onClose: () => history.go(back_degree),
                })
              : true,
        },
      ],
    });
  }, []);

  async function attemptSubmit(event) {
    if (!canSubmit(is_submitting)) return false; // wait for previous attempt to complete or fail

    const showError = (message) => toast.error(message, toast_config);

    if (!inputsAreValid({ email, phone, phone_hints }, { showError }))
      return false;
    const { phone: prepped_phone } = prepare_inputs({ phone });

    const server_response = await verifyPhone(
      {
        link_code,
        phone: prepped_phone,
        email,
      },
      {
        toast,
        toast_config,
        beforeRequest: () => setIsSubmitting(true),
        afterResponse: () => setIsSubmitting(false),
      }
    );
    console.log({ server_response });

    if (server_response.status) {
      const query_string =
        `continue_token=${server_response.data.continue_token}` +
        `&continue_token_expires_at=${server_response.data.continue_token_expires_at}` +
        `&phone=${prepped_phone}&email=${email}&slug=${link_code}`;
      history.push(`${CHILD_URLS.LOAN_CONTINUATION.OTP}/?${query_string}`);
    }
  }

  return (
    <div className="phone-brk-edit-container">
      <Helmet>
        <title>Complete Your Edfin Loan Application (Enter Phone)</title>
        <meta
          property="og:title"
          content="Complete Your Edfin Loan Application (Enter Phone)"
        />
      </Helmet>
      <div className="phone-brk-edit-container01">
        <div className="phone-brk-edit-container02">
          <button type="button" className="phone-brk-edit-button button">
            {link_code}
          </button>
          <div className="phone-brk-edit-container03">
            <div className="phone-brk-edit-container04">
              <div className="phone-brk-edit-container05">
                <h1 className="phone-brk-edit-text">
                  Welcome back, {first_name}! 👋🏼
                </h1>
              </div>
              <div className="phone-brk-edit-container06">
                <h2 className="phone-brk-edit-text1">
                  <span>Kindly complete this form to proceed</span>
                  <span className="phone-brk-edit-text3">*</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="phone-brk-edit-container07">
            <div className="phone-brk-edit-container08">
              <div className="phone-brk-edit-container09">
                <div className="phone-brk-edit-container10">
                  <svg viewBox="0 0 1024 1024" className="phone-brk-edit-icon">
                    <path d="M981.333 721.92c0.683-30.464-10.069-59.904-28.715-82.901-19.627-24.149-48.171-41.259-82.133-46.080-34.133-4.181-72.32-13.397-110.336-27.563-18.475-6.784-38.101-9.301-57.344-7.424-28.288 2.731-55.765 14.891-77.611 36.48l-30.72 30.72c-75.52-47.659-143.36-113.792-195.541-195.797l30.976-30.976c13.739-14.080 24.021-30.976 30.165-49.323 9.045-26.965 9.131-57.003-1.664-85.803-12.331-32.128-22.101-70.144-27.477-110.72-4.437-30.507-19.413-57.472-41.088-77.013-22.997-20.693-53.589-33.195-86.485-32.853h-127.829c-3.755 0-7.765 0.171-11.648 0.512-35.157 3.2-65.792 20.395-86.741 45.483s-32.341 58.325-29.141 93.824c12.8 131.243 58.24 266.368 137.216 388.352 64.085 102.955 155.648 197.248 268.715 269.056 109.568 72.405 242.517 122.112 387.669 137.856 3.925 0.384 8.149 0.555 12.288 0.555 35.328-0.128 67.328-14.635 90.368-37.845s37.248-55.339 37.12-90.496zM896 721.92v128c0.043 11.947-4.651 22.613-12.373 30.379s-18.304 12.587-30.123 12.629l-3.755-0.171c-130.56-14.208-250.581-59.221-348.757-124.117-103.595-65.835-185.984-150.955-243.285-242.944-72.405-111.787-113.28-233.856-124.757-351.488-1.024-11.435 2.731-22.443 9.771-30.891s17.195-14.080 28.928-15.147l131.712-0.171c11.563-0.128 21.632 4.011 29.312 10.923 7.253 6.571 12.288 15.616 13.781 25.941 6.059 45.739 17.408 90.325 32.299 129.067 3.456 9.216 3.413 19.072 0.427 28.075-2.091 6.187-5.589 11.989-10.325 16.853l-53.845 53.803c-13.824 13.824-16.171 34.731-6.912 51.243 67.584 118.827 163.797 211.499 272.256 272.128 16.939 9.472 37.632 6.144 50.987-7.083l54.187-54.187c7.083-6.997 16.085-10.965 25.515-11.904 6.485-0.64 13.227 0.213 19.584 2.56 43.605 16.256 88.32 27.136 129.451 32.171 10.283 1.451 19.712 7.083 26.24 15.147 6.272 7.723 9.856 17.579 9.643 29.099z"></path>
                  </svg>
                  <label className="phone-brk-edit-text4">
                    Enter the complete phone number ({phone_hints.start}******
                    {phone_hints.end})
                  </label>
                </div>
                <input
                  type="text"
                  autoFocus
                  placeholder="e.g 08091275825"
                  className="phone-brk-edit-textinput input"
                  value={phone}
                  onChange={(event) => setCompletePhone(event.target.value)}
                />
              </div>
              <div className="phone-brk-edit-container11">
                <div className="phone-brk-edit-container12">
                  <svg viewBox="0 0 1024 1024" className="phone-brk-edit-icon2">
                    <path d="M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z"></path>
                  </svg>
                  <label className="phone-brk-edit-text5">
                    Email address (we would verify it)
                  </label>
                </div>
                <input
                  type="text"
                  placeholder="e.g falade@gmail.com"
                  className="phone-brk-edit-textinput1 input"
                  value={email}
                  onChange={(event) => setEmailAddress(event.target.value)}
                />
              </div>
              <div className="phone-brk-edit-container13">
                <button
                  type="button"
                  className="phone-brk-edit-button1 button"
                  onClick={attemptSubmit}
                  disabled={is_submitting}
                >
                  <span className="phone-brk-edit-text6">
                    <span>Continue</span>
                    <br></br>
                  </span>
                  <svg viewBox="0 0 1024 1024" className="phone-brk-edit-icon4">
                    <path d="M621.254 877.254l320-320c24.994-24.992 24.994-65.516 0-90.51l-320-320c-24.994-24.992-65.516-24.992-90.51 0-24.994 24.994-24.994 65.516 0 90.51l210.746 210.746h-613.49c-35.346 0-64 28.654-64 64s28.654 64 64 64h613.49l-210.746 210.746c-12.496 12.496-18.744 28.876-18.744 45.254s6.248 32.758 18.744 45.254c24.994 24.994 65.516 24.994 90.51 0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneBrkEdit;
