import Axios from "axios";

const BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "https://api.mwd.edfinmfb.com/edfin/v1";
const HEADERS = {};

const VALIDATE_STATUS_DEFAULT = true;

export function call(
  payload,
  { base_url, validate_status = VALIDATE_STATUS_DEFAULT, headers } = {
    validate_status: VALIDATE_STATUS_DEFAULT,
  }
) {
  return Axios({
    baseURL: base_url || BASE_URL,
    ...payload,
    headers: { ...HEADERS, ...headers }, // allow new override old
    validateStatus: (status) =>
      validate_status ? status >= 200 && status < 500 : true,
  });
}

export async function safe_api_wrapper(
  async_cb,
  {
    beforeRequest,
    afterResponse,
    loading_text,
    toast,
    toast_config,
    context = {},
  }
) {
  beforeRequest?.();

  const toast_id = toast(loading_text ? loading_text : "Running query...", {
    ...toast_config,
    autoClose: false,
  });

  try {
    await async_cb(context);
  } catch (error) {
    if (error.name === "AxiosError" && error.code === "ERR_NETWORK")
      context.response = {
        data: {
          status: false,
          message: "Internet connection unstable. Kindly try again",
        },
      };
    else if (error.response) context.response = error.response;
    else
      context.response = {
        data: { status: false, message: error.message, data: { error } },
      };
  }

  context.status = context.response?.status === 200 ? true : false;
  context.message = context.response?.data?.message;
  context.data = context.response?.data?.data;

  const update_config = {
    ...toast_config,
    type: context.status ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    autoClose: context.status ? 10000 : 8000,
    render: context.message || "Template message",
  };
  toast.update(toast_id, update_config);

  afterResponse?.(context.response);

  return context;
}
