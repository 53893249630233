import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./otp-brk-edit.css";

import * as Time from "../utils/time";
import { initializeFromQuery } from "../utils/url";
import { canSubmit } from "../utils/form";
import { verifyPhone, verifyEmail } from "../apis/server";

const OTPBrkEdit = (props) => {
  const history = useHistory();

  const { toast, toast_config, CHILD_URLS } = props;

  const [link_code, setLinkCode] = React.useState("");
  const [expire_at, setExpireAt] = React.useState("");
  const [current_time, setCurrentTime] = React.useState(Time.now());
  const [otp, setOtp] = React.useState("");
  const [continue_token, setContinueToken] = React.useState("");
  const [email, setEmailAddress] = React.useState("");
  const [phone, setCompletePhone] = React.useState("");
  const [resend_otp_count, setResendOtpCount] = React.useState(0);
  const [is_submitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    const onClose = () => history.go(-1);

    initializeFromQuery({
      url: window.location.search,
      callback_per_query: [
        {
          query_tag: "continue_token",
          callback: setContinueToken,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid token detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "continue_token_expires_at",
          callback: setExpireAt,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error(
                  "Invalid token expiration time. Kindly retry later",
                  { ...toast_config, onClose }
                )
              : true,
        },
        {
          query_tag: "phone",
          callback: setCompletePhone,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid phone detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "email",
          callback: setEmailAddress,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid email detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "slug",
          callback: setLinkCode,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid link-code detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "resend_otp_count",
          callback: (v) => setResendOtpCount(Number(v)),
        },
      ],
    });
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Time.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const hasNotExpired = () =>
    Time.moment(expire_at).diff(current_time, "milliseconds") > 0;

  const computeTimeLeft = () => {
    const minutes_left = Time.moment(expire_at).diff(current_time, "minutes");
    const seconds_left =
      Time.moment(expire_at).diff(current_time, "seconds") % 60;
    return {
      string: `${minutes_left}:${
        seconds_left > 9 ? seconds_left : "0" + seconds_left
      }${minutes_left > 0 ? " minutes" : " seconds"}`,
      urgent: minutes_left === 0,
    };
  };

  async function attemptGoingBack(event) {
    const query_string =
      `continue_token=${continue_token}` +
      `&phone=${phone}&email=${email}&slug=${link_code}`;
    history.push(`${CHILD_URLS.LOAN_CONTINUATION.EMAIL}/?${query_string}`);
  }

  async function resendOTP(event) {
    if (!canSubmit(is_submitting)) return false; // wait for previous attempt to complete or fail

    const server_response = await verifyPhone(
      {
        link_code,
        phone,
        email,
        resend_otp: true,
        previous_token: continue_token,
      },
      {
        toast,
        toast_config,
        beforeRequest: () => setIsSubmitting(true),
        afterResponse: () => setIsSubmitting(false),
      }
    );
    console.log({ server_response });

    if (server_response.status) {
      const query_string =
        `continue_token=${server_response.data.continue_token}` +
        `&continue_token_expires_at=${server_response.data.continue_token_expires_at}` +
        `&phone=${phone}&email=${email}&slug=${link_code}&resend_otp_count=${
          resend_otp_count + 1
        }`; // added resent_otp to ensure the url changes triggering a history addition
      // }&previous_continue_token_${resend_otp_count}=${continue_token}`;
      history.push(`${CHILD_URLS.LOAN_CONTINUATION.OTP}/?${query_string}`);
      window.location.reload(false);
    }
  }

  async function attemptSubmit(event) {
    if (!canSubmit(is_submitting)) return false; // wait for previous attempt to complete or fail

    const server_response = await verifyEmail(
      {
        otp,
        continue_token,
      },
      {
        toast,
        toast_config,
        beforeRequest: () => setIsSubmitting(true),
        afterResponse: () => setIsSubmitting(false),
      }
    );
    console.log({ server_response });

    if (server_response.status)
      toast.info("Redirecting to next phase!", {
        ...toast_config,
        onClose: () => {
          window.location.href = server_response.data.redirect_to;
        },
      });
  }

  return (
    <div className="otp-brk-edit-container">
      <Helmet>
        <title>Complete Your Edfin Loan Application (Enter OTP)</title>
        <meta
          property="og:title"
          content="Complete Your Edfin Loan Application (Enter OTP)"
        />
      </Helmet>
      <div className="otp-brk-edit-container01">
        <div className="otp-brk-edit-container02">
          <button type="button" className="otp-brk-edit-button button">
            {link_code}
          </button>
          <div className="otp-brk-edit-container03">
            <div className="otp-brk-edit-container04">
              <div className="otp-brk-edit-container05">
                <h1 className="otp-brk-edit-text">Almost there 💯</h1>
              </div>
              <div
                className="otp-brk-edit-container06"
                onClick={attemptGoingBack}
              >
                <h2 className="otp-brk-edit-text01">Change email: {email}</h2>
                <svg viewBox="0 0 1024 1024" className="otp-brk-edit-icon">
                  <path d="M469.333 128h-298.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v597.333c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-597.333c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h298.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM759.168 76.501l-405.333 405.333c-5.205 5.163-9.259 11.947-11.221 19.84l-42.667 170.667c-1.664 6.4-1.792 13.568 0 20.693 5.717 22.869 28.885 36.779 51.755 31.061l170.667-42.667c7.125-1.749 14.080-5.504 19.84-11.221l405.333-405.333c25.984-25.984 38.997-60.16 38.997-94.165s-13.013-68.181-38.997-94.165-60.203-39.040-94.208-39.040-68.181 13.013-94.165 38.997zM819.499 136.832c9.344-9.344 21.504-13.995 33.835-13.995s24.491 4.651 33.835 13.995 13.995 21.504 13.995 33.835-4.651 24.491-13.995 33.835l-396.971 396.971-90.197 22.571 22.571-90.197z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="otp-brk-edit-container07">
            <div className="otp-brk-edit-container08">
              <div className="otp-brk-edit-container09">
                <div className="otp-brk-edit-container10">
                  <svg viewBox="0 0 1024 1024" className="otp-brk-edit-icon02">
                    <path d="M213.333 512h597.333c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v298.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-597.333c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-298.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501zM768 426.667v-128c0-70.699-28.715-134.741-74.965-181.035s-110.336-74.965-181.035-74.965-134.741 28.715-181.035 74.965-74.965 110.336-74.965 181.035v128h-42.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v298.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h597.333c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-298.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504zM341.333 426.667v-128c0-47.147 19.072-89.728 50.005-120.661s73.515-50.005 120.661-50.005 89.728 19.072 120.661 50.005 50.005 73.515 50.005 120.661v128z"></path>
                  </svg>
                  <label className="otp-brk-edit-text02">
                    OTP sent to email address (
                    {hasNotExpired() ? (
                      <span
                        style={
                          computeTimeLeft().urgent ? { color: "orange" } : {}
                        }
                      >
                        Expires in&nbsp;
                        {computeTimeLeft().string}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        Expired {Time.moment(expire_at).from(current_time)}
                      </span>
                    )}
                    )
                  </label>
                </div>
                <input
                  type="text"
                  placeholder="e.g A8C13X"
                  className="otp-brk-edit-textinput input"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                />
                <div className="otp-brk-edit-container11" onClick={resendOTP}>
                  <label className="otp-brk-edit-text03">
                    <span>
                      Resend OTP
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <br></br>
                  </label>
                  <svg viewBox="0 0 1024 1024" className="otp-brk-edit-icon04">
                    <path d="M170.667 853.291c-4.096 0-8.149-0.64-12.203-1.792-18.091-5.376-30.464-22.016-30.464-40.875v-42.667c0-204.8 144.256-378.197 341.333-417.963v-72.704c0-22.784 8.875-44.203 24.96-60.331 32.256-32.299 88.533-32.256 120.704-0.043l268.288 264.661c8.149 8.021 12.715 18.901 12.715 30.379s-4.565 22.357-12.715 30.379l-268.501 264.832c-31.829 31.829-88.192 32.043-120.448-0.213-16.128-16.128-25.003-37.547-25.003-60.331v-61.312c-106.453 8.576-192.981 42.027-262.997 148.651-8.107 12.288-21.547 19.328-35.669 19.328zM512 597.76c11.179 0 42.667-0.427 42.667-0.427v149.291l237.909-234.667-237.909-234.496v149.205c0 0-16.384-0.171-38.016 2.219-145.749 16.128-261.333 122.197-294.059 259.413 90.496-73.728 194.176-88.149 289.408-90.539z"></path>
                  </svg>
                </div>
              </div>
              <div className="otp-brk-edit-container12">
                <button
                  type="button"
                  className="otp-brk-edit-button1 button"
                  onClick={attemptGoingBack}
                >
                  <svg viewBox="0 0 1024 1024" className="otp-brk-edit-icon06">
                    <path d="M896 470v84h-604l152 154-60 60-256-256 256-256 60 60-152 154h604z"></path>
                  </svg>
                  <span className="otp-brk-edit-text06">
                    <span>Back</span>
                    <br></br>
                  </span>
                </button>
                <button
                  type="button"
                  className="otp-brk-edit-button2 button"
                  onClick={attemptSubmit}
                >
                  <span className="otp-brk-edit-text09">
                    <span>Continue</span>
                    <br></br>
                  </span>
                  <svg viewBox="0 0 1024 1024" className="otp-brk-edit-icon08">
                    <path d="M621.254 877.254l320-320c24.994-24.992 24.994-65.516 0-90.51l-320-320c-24.994-24.992-65.516-24.992-90.51 0-24.994 24.994-24.994 65.516 0 90.51l210.746 210.746h-613.49c-35.346 0-64 28.654-64 64s28.654 64 64 64h613.49l-210.746 210.746c-12.496 12.496-18.744 28.876-18.744 45.254s6.248 32.758 18.744 45.254c24.994 24.994 65.516 24.994 90.51 0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPBrkEdit;
