import React from "react";

import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

import "./email-brk-edit.css";

import { initializeFromQuery } from "../utils/url";
import { inputsAreValid, canSubmit } from "../utils/form";
import { verifyPhone } from "../apis/server";

const EmailBrkEdit = (props) => {
  const history = useHistory();

  const { toast, toast_config, CHILD_URLS } = props;

  const [link_code, setLinkCode] = React.useState("");
  const [continue_token, setContinueToken] = React.useState("");
  const [email, setEmailAddress] = React.useState("");
  const [phone, setCompletePhone] = React.useState("");
  const [new_email, setNewEmailAddress] = React.useState("");
  const [is_submitting, setIsSubmitting] = React.useState(false);

  React.useEffect(() => {
    const onClose = () => history.go(-1);

    initializeFromQuery({
      url: window.location.search,
      callback_per_query: [
        {
          query_tag: "continue_token",
          callback: setContinueToken,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid token detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "phone",
          callback: setCompletePhone,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid phone detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "email",
          callback: (v) => {
            setEmailAddress(v);
            setNewEmailAddress(v);
          },
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid email detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
        {
          query_tag: "slug",
          callback: setLinkCode,
          validate: (v) =>
            [null, undefined, ""].includes(v)
              ? toast.error("Invalid link-code detected. Kindly retry later", {
                  ...toast_config,
                  onClose,
                })
              : true,
        },
      ],
    });
  }, []);

  async function attemptSubmit(event) {
    if (!canSubmit(is_submitting)) return false; // wait for previous attempt to complete or fail

    if (new_email === email) {
      toast.warn("No email change detected. Going back to previous page");
      history.goBack();
    }

    const showError = (message) => toast.error(message, toast_config);

    if (
      !inputsAreValid(
        { email: new_email, phone },
        { showError, validate_phone_partially: true }
      )
    )
      return false;

    const server_response = await verifyPhone(
      {
        link_code,
        phone,
        email: new_email,
        change_email: true,
        previous_token: continue_token,
      },
      {
        toast,
        toast_config,
        beforeRequest: () => setIsSubmitting(true),
        afterResponse: () => setIsSubmitting(false),
      }
    );
    console.log({ server_response });

    if (server_response.status) {
      const query_string =
        `continue_token=${server_response.data.continue_token}` +
        `&continue_token_expires_at=${server_response.data.continue_token_expires_at}` +
        `&phone=${phone}&email=${new_email}&slug=${link_code}`;
      history.push(`${CHILD_URLS.LOAN_CONTINUATION.OTP}/?${query_string}`);
    }
  }

  return (
    <div className="email-brk-edit-container">
      <Helmet>
        <title>Complete Your Edfin Loan Application (Change Email)</title>
        <meta
          property="og:title"
          content="Complete Your Edfin Loan Application (Change Email)"
        />
      </Helmet>
      <div className="email-brk-edit-container01">
        <div className="email-brk-edit-container02">
          <button type="button" className="email-brk-edit-button button">
            {link_code}
          </button>
          <div className="email-brk-edit-container03">
            <div className="email-brk-edit-container04">
              <div className="email-brk-edit-container05">
                <h1 className="email-brk-edit-text">
                  You&apos;re close! 🫱🏼‍🫲🏾
                </h1>
              </div>
              <div className="email-brk-edit-container06">
                <h2 className="email-brk-edit-text1">
                  Phone number (verified): +{phone}
                </h2>
                <svg viewBox="0 0 1024 1024" className="email-brk-edit-icon">
                  <path d="M170 256v598h598v84h-598q-34 0-59-25t-25-59v-598h84zM532 598l278-282-60-60-218 220-88-88-60 60zM854 86q34 0 59 25t25 59v512q0 34-25 60t-59 26h-512q-34 0-60-26t-26-60v-512q0-34 26-59t60-25h512z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="email-brk-edit-container07">
            <div className="email-brk-edit-container08">
              <div className="email-brk-edit-container09">
                <div className="email-brk-edit-container10">
                  <svg viewBox="0 0 1024 1024" className="email-brk-edit-icon2">
                    <path d="M128 337.963l359.552 251.691c14.507 10.027 33.92 10.496 48.939 0l359.509-251.691v430.037c0 11.605-4.693 22.229-12.587 30.080s-18.475 12.587-30.080 12.587h-682.667c-11.605 0-22.229-4.693-30.080-12.587s-12.587-18.475-12.587-30.080zM42.667 256.512v511.488c0 35.328 14.507 67.371 37.547 90.453s55.125 37.547 90.453 37.547h682.667c35.328 0 67.371-14.507 90.453-37.547s37.547-55.125 37.547-90.453v-511.488c0-0.427 0-0.853 0-1.28-0.213-35.029-14.635-66.773-37.547-89.685-23.083-23.040-55.125-37.547-90.453-37.547h-682.667c-35.328 0-67.371 14.507-90.453 37.547-22.912 22.912-37.333 54.656-37.547 89.728 0 0.213 0 0.469 0 0.725zM891.477 236.971l-379.477 265.6-379.477-265.6c2.048-4.096 4.779-7.808 8.021-11.051 7.893-7.893 18.517-12.587 30.123-12.587h682.667c11.605 0 22.229 4.693 30.080 12.587 3.243 3.243 5.973 6.997 8.021 11.051z"></path>
                  </svg>
                  <label className="email-brk-edit-text2">
                    Email address (we would verify it)
                  </label>
                </div>
                <input
                  type="text"
                  placeholder="e.g falade@gmail.com"
                  className="email-brk-edit-textinput input"
                  value={new_email}
                  onChange={(event) => setNewEmailAddress(event.target.value)}
                />
              </div>
              <div className="email-brk-edit-container11">
                <button
                  type="button"
                  className="email-brk-edit-button1 button"
                  onClick={attemptSubmit}
                >
                  <span className="email-brk-edit-text3">
                    <span>Continue</span>
                    <br></br>
                  </span>
                  <svg viewBox="0 0 1024 1024" className="email-brk-edit-icon4">
                    <path d="M621.254 877.254l320-320c24.994-24.992 24.994-65.516 0-90.51l-320-320c-24.994-24.992-65.516-24.992-90.51 0-24.994 24.994-24.994 65.516 0 90.51l210.746 210.746h-613.49c-35.346 0-64 28.654-64 64s28.654 64 64 64h613.49l-210.746 210.746c-12.496 12.496-18.744 28.876-18.744 45.254s6.248 32.758 18.744 45.254c24.994 24.994 65.516 24.994 90.51 0z"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailBrkEdit;
