import { call, safe_api_wrapper } from "../utils/api";

export async function verifyPhone(
  { link_code, phone, email, resend_otp, change_email, previous_token },
  { toast, toast_config, beforeRequest, afterResponse }
) {
  return await safe_api_wrapper(
    async (context) => {
      // -- start --

      context.response = await call({
        url: `/application/verify-phone`,
        method: "POST",
        data: {
          slug: link_code,
          msisdn: phone,
          email_address: email,
          resend_email_otp: resend_otp,
          change_email,
          previous_continue_token: previous_token,
        },
      });

      // -- end --
    },
    {
      loading_text: resend_otp
        ? "Resending OTP..."
        : change_email
        ? "Changing email..."
        : "Verifying details...",
      beforeRequest,
      afterResponse,
      toast,
      toast_config,
    }
  );
}

export async function verifyEmail(
  { continue_token, otp },
  { toast, toast_config, beforeRequest, afterResponse }
) {
  return await safe_api_wrapper(
    async (context) => {
      // -- start --

      context.response = await call({
        url: `/application/verify-email`,
        method: "POST",
        data: {
          continue_token,
          otp,
        },
      });

      // -- end --
    },
    {
      loading_text: "Verifying email...",
      beforeRequest,
      afterResponse,
      toast,
      toast_config,
    }
  );
}
