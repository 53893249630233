import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./style.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import OTPBrkEdit from "./views/otp-brk-edit";
import EmailBrkEdit from "./views/email-brk-edit";
import PhoneBrkEdit from "./views/phone-brk-edit";
import NotFound from "./views/not-found";

console.info("Environment", {
  env: process.env,
  base_url: process.env.REACT_APP_API_BASE_URL,
});

const App = () => {
  const toast_config = {
    autoClose: 5000,
  };

  const loan_continue_prefix = "/loan/continue";

  const CHILD_URLS = {
    LOAN_CONTINUATION: {
      PHONE: `${loan_continue_prefix}`,
      OTP: `${loan_continue_prefix}/otp`,
      EMAIL: `${loan_continue_prefix}/email`,
    },
  };

  return (
    <Router>
      <Switch>
        <Route
          render={(props) => (
            <PhoneBrkEdit
              {...props}
              toast={toast}
              toast_config={toast_config}
              CHILD_URLS={CHILD_URLS}
            />
          )}
          exact
          path={CHILD_URLS.LOAN_CONTINUATION.PHONE}
        />
        <Route
          render={(props) => (
            <OTPBrkEdit
              {...props}
              toast={toast}
              toast_config={toast_config}
              CHILD_URLS={CHILD_URLS}
            />
          )}
          exact
          path={CHILD_URLS.LOAN_CONTINUATION.OTP}
        />
        <Route
          render={(props) => (
            <EmailBrkEdit
              {...props}
              toast={toast}
              toast_config={toast_config}
              CHILD_URLS={CHILD_URLS}
            />
          )}
          exact
          path={CHILD_URLS.LOAN_CONTINUATION.EMAIL}
        />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
      <ToastContainer />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
