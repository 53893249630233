export function initializeFromQuery({ url, callback_per_query }) {
  const query = new URLSearchParams(url);
  if (callback_per_query)
    callback_per_query.forEach(({ callback, query_tag, validate }) => {
      const value = query.get(query_tag);
      if (validate && validate(value)) return callback(value);
      else if (!validate) return callback(value);
    });
  return query;
}
